@import '../../assets/stylesheets/common/variables';

.loginPage,
.signUpPage,
.confirmLoginPage {
  padding-top: 30px;
  h4 {
    text-align: center;
    margin: auto;
    display: block;
    font-weight: $font-weight-medium;
    margin-bottom: $spacing-1;
    color: $black-text;
  }

  .loginPageHeader,
  .signUpPageHeader,
  .confirmLoginPageHeader {
    background-color: $white;
    border-radius: $spacing-1 - 1;
    text-align: center;
    padding: $spacing-4;

    .pageSubtitle {
      width: 75%;
      margin-left: auto;
      margin-right: auto;
    }

    .pageTitle {
      font-size: inherit;
      margin: $spacing-3 0 0;
      line-height: inherit;
      color: inherit;
    }
  }

  .promptDescription {
    font-size: $font-body;
    line-height: $spacing-4;
    @include unused-for-desktop {
      width: 70%;
      margin: auto;
    }
  }

  .paneSeparation {
    text-align: center;
    text-transform: uppercase;
  }

  .captcha {
    div {
      padding-bottom: 5px;
      margin: auto;
    }
  }

  .loginField {
    &.middleField {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    padding: $spacing-3;
    margin-top: $spacing-1 - 4;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: $spacing-1;
    border-top-right-radius: $spacing-1;
  }

  .formControlButton,
  .formControlBottom {
    text-transform: uppercase;
    font-weight: 900;
    padding: $spacing-3;
    border-radius: $radius-oval-container;
    margin-top: $spacing-4;
  }

  .formControlBottom {
    margin-top: 0;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  :global(.modal-body) {
    padding: $spacing-4 $spacing-8;
  }

  .pageBody {
    p {
      text-align: center;
    }
    .privacyPolicy {
      text-align: left;
    }
    padding: $spacing-2;
    color: $grey-lightest-text;
    border-radius: $radius-oval-container;
    background-color: #f8f8f8;
    max-width: 600px;
    margin: 0 auto;

    input[type='text'],
    input[type='email'],
    input[type='password'] {
      width: 100%;
      background-color: $white;
      border: 0px solid;
      border-radius: $spacing-3 + 1;
      height: $spacing-4;
      margin: ($spacing-1 - 4) 0;
      padding: $spacing-6 ($spacing-3 + 1);
      font-size: $font-size-midlarger;
      font-weight: $font-weight-normal;
    }

    input[type='text'] + label,
    input[type='password'] + label {
      display: none;
      position: relative;
      top: -($spacing-6);
      left: $spacing-3 + 1;
      margin-top: -($spacing-4);
      font-size: $spacing-2 + 2;
      text-transform: uppercase;
    }

    input[type='text']:focus::-webkit-input-placeholder,
    input[type='password']:focus::-webkit-input-placeholder {
      position: absolute;
      top: 0;
      opacity: 0;
    }

    input[type='text']:focus,
    input[type='text']:not(:placeholder-shown),
    input[type='password']:focus,
    input[type='password']:not(:placeholder-shown) {
      padding-bottom: $spacing-4 !important;
      padding-top: $spacing-8 !important;
    }

    input[type='text']:focus + label,
    input[type='text']:not(:placeholder-shown) + label,
    input[type='password']:focus + label,
    input[type='password']:not(:placeholder-shown) + label {
      display: block;
    }
  }

  .bodyTitle {
    margin-bottom: 1.25rem;
  }

  .errorMessages {
    color: $error;
    font-style: italic;
  }

  .proInfoLabel {
    border: 1px solid;
    padding: $spacing-1 $spacing-2;
    width: 100%;
    border-color: $grey;

    .infoIcon {
      margin-right: $spacing-1;
      color: $carolina;
    }
  }

  .btnGoogle {
    width: 300px;
    padding: 15px 0;
    margin: auto;
  }

  .forgotPassword {
    text-align: left !important;
  }

  .divider {
    margin-bottom: $spacing-4;

    .dividerLine {
      width: 43%;
      height: 5px;
      border-top: 1px solid $grey-dark-on-light;
      display: inline-block;
    }

    .dividerLineL {
      margin-right: $spacing-1;
    }

    .dividerLineR {
      margin-left: $spacing-1;
    }

    .dividerText {
      font-weight: bold;
    }
  }

  .updatePasswordPolicy {
    text-align: center;
    margin-top: 0.313rem;
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1.4;
    font-weight: $font-weight-medium;
  }
  .passwordPolicy {
    padding: 1rem 2.5rem 0 2.5rem;
    p {
      font-weight: $font-weight-medium;
      text-align: left;
      font-size: $font-size-large;
      margin-bottom: 0.1rem;
      color: $black-text;
    }
    ul {
      text-align: left;
      padding: 0;
      margin-left: 1.563rem;
      font-weight: $font-weight-normal;
      font-size: $font-size-normal;
    }
  }
}

.tooltip {
  position: absolute;
  background: #fff3c6;
  padding: ($spacing-1 - 1) ($spacing-3 + 1);
  font-size: $font-size-mid;
  margin-top: -1 * ($spacing-14 - 1);
  margin-left: $spacing-4;
  z-index: 20;
  padding-left: 0;
}

.rePasswordTooltip {
  margin-top: -1 * ($spacing-4 - 1);
  padding-left: $spacing-3;
}

.link {
  cursor: pointer;
}
